import { view } from '@risingstack/react-easy-state';
import './App.scss';
import mainStore from './mainStore';
import fetchData from './fetchData';
import PageContents from './PageContents.js';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import { transformText } from './transformText';
import { preprocessText } from './preprocessText';
import bookletPageReindexer from './bookletPageReindexer';
import PageEditor from './PageEditor';

function App() {
    //return <oldCards />

    let subpages = mainStore.pages;
    subpages.forEach((sp, i) => sp.number = i);

    if (mainStore.rearrangeForBooklet) {
        subpages = bookletPageReindexer(subpages);
    }

    const pages = [];

    if (mainStore.forPrint) {
        subpages.forEach((p, i) => {
            let pc = [];
            if (i % 2 === 0) {
                pages.push(pc);
            } else {
                pc = pages[parseInt(i / 2)];
            }
            pc.push(p);
        })
        return [
            pages.map((p, pi) => <div key={pi} className="A4page" onClick={() => {if(pi === 0) mainStore.forPrint = !mainStore.forPrint}}>
                {p.map((sp, i) => <div key={i} className={(sp.class ? sp.class : '') + (i === 0 ? " book-page book-page-left" : " book-page book-page-right")}>
                    <ReactMarkdown children={preprocessText(sp.content)} rehypePlugins={[rehypeRaw]} />
                    <div className="page-number">{sp.number}</div>
                </div>)}
            </div>),
        ]
    } else {
        if(mainStore.showSelectedOnly) {
            pages.push([mainStore.selectedPage]);
        } else {
            subpages.forEach((p, i) => {
                pages.push([p]);
            })
        }
        return [
            pages.map((p, pi) => <div key={pi} className="A5page" onClick={() => {if(pi === 0) mainStore.forPrint = !mainStore.forPrint}}>
                {p.map((sp, i) => <div key={i} className={(sp.class ? sp.class : '') + (i === 0 ? " book-page book-page-left" : " book-page book-page-right")}>
                    <ReactMarkdown children={preprocessText(sp.content)} rehypePlugins={[rehypeRaw]} />
                    <div className="page-number">{sp.number}</div>
                </div>)}
            </div>),
            <PageEditor />
        ]
    }
}

export default view(App);
