export default function bookletPageReindexer(pages) {
    //each page has 4 booklet subpages.
    // for page index pi
    // for subpage index si
    // we need to get the final subpage index.

    // first sheet of paper has 4 elements:
    // front is 2 and n-1
    // back is 1 and n

    // second sheet of paper is 3, n-3, 2, n-2

    // 1. ceil(n / 4) = amount of sheets = sheetAmount
    // 2. add empty subpages at the end if needed.
    // 3. for each sheet, fill indexes:
    const tempPages = pages.filter((e) => e.notInBooklet !== true);
    const totalSheets = Math.ceil(tempPages.length / 4);
    const totalSubpages = totalSheets * 4;
    while(tempPages.length < totalSubpages) tempPages.push({number: tempPages.length, content: '', class: 'nopagenumber'})

    console.log(totalSheets, totalSubpages)
    const result = [...tempPages];

    for(let i = 0; i < totalSheets; i++){
        console.log(i*4 + 0, i*2 + 1)
        result[i*4 + 0] = tempPages[i*2 + 1];
        result[i*4 + 1] = tempPages[totalSubpages - i*2 - 2];
        result[i*4 + 2] = tempPages[totalSubpages - i*2 - 1];
        result[i*4 + 3] = tempPages[i*2 + 0];
    }

    console.log(result);
    return result;
}