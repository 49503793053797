import { view } from "@risingstack/react-easy-state";
import MDEditor from "@uiw/react-md-editor";
import { Component } from "react";
import rehypeSanitize from "rehype-sanitize";
import mainStore from "./mainStore";
import './PageEditor.scss';

function changePage(diff) {
    mainStore.pageNumber += diff;
    if(mainStore.pageNumber < 0) mainStore.pageNumber = 0;
    if(mainStore.pageNumber >= mainStore.pages.length) {
        mainStore.pages.push({class: '', content: ''})
    }

    if((diff === -1) && (mainStore.pages.length -1 === mainStore.pageNumber + 1) && (mainStore.selectedPage.content === '')){
        mainStore.pages.pop();
    }

    mainStore.selectedPage = mainStore.pages[mainStore.pageNumber];
}

class PageEditor extends Component {
    render() {
        if(mainStore.selectedPage.content === undefined){
            mainStore.selectedPage = mainStore.pages[0];
            mainStore.pageNumber = 0;
        }
        return <div className="page-editor">
            <div className="pageSelector">
                <i className={(mainStore.rearrangeForBooklet ? "fal" : "fas") + " fa-book-open boxed clickable"} onClick={(e) => mainStore.rearrangeForBooklet = !mainStore.rearrangeForBooklet}/>
                <i className={(mainStore.showSelectedOnly ? "fal" : "fas") + " fa-files boxed clickable"} onClick={(e) => mainStore.showSelectedOnly = !mainStore.showSelectedOnly}/>
                <i className="fas fa-minus boxed clickable" onClick={(e) => changePage(-1)}/>
                <i className="fas boxed">{mainStore.pageNumber}</i>
                <i className="fas fa-plus boxed clickable" onClick={(e) => changePage(1)}/><br />
                Class: <input value={mainStore.selectedPage.class} onChange={(e) => mainStore.selectedPage.class = e.target.value} /><br />
                Print in booklet mode: <i className="fas boxed clickable" onClick={(e) => mainStore.selectedPage.notInBooklet = !mainStore.selectedPage.notInBooklet}>{mainStore.notInBooklet ? 'NO' : 'YES'}</i>
            </div>
            <MDEditor
                value={mainStore.selectedPage.content}
                onChange={(e) => mainStore.selectedPage.content = e}
                height="calc(100vh - 10rem)"
                components={{
                    preview: (e) => <div />
                }}
                preview='edit'
            />
        </div>
    }
}

export default view(PageEditor);