import { view } from '@risingstack/react-easy-state';
import mainStore from './mainStore';
import fetchData from './fetchData';
import { transformText } from './transformText';


function PageContents(props) {
    //return <oldCards />
    const contents = props.contents;
    return transformText(contents);
}

export default view(PageContents);
