import Dice from "./components/Dice"

const funmap = {
    ' ': (e, i) => <i key={i} className="far fa-fw fa-square" />,
    'D4': (e, i) => <i key={i} className="far fa-fw fa-dice-d4" />,
    'D6': (e, i) => <i key={i} className="far fa-fw fa-dice-d6" />,
    'D8': (e, i) => <i key={i} className="far fa-fw fa-dice-d8" />,
    'D10': (e, i) => <i key={i} className="far fa-fw fa-dice-d10" />,
    'D12': (e, i) => <i key={i} className="far fa-fw fa-dice-d12" />,
    'Dice': (e, i) => <Dice key={i} className={' ' + e.classes.join(' ')} {...e.other}>{e.content}</Dice>,
    'PageCover': (e, i) => <div key={i} className="page-image" style={ { backgroundImage: `url(${e.params[1]}` } } />,
    'b': (e, i) => <b key={i}>{e.params[1]}</b>,
    'i': (e, i) => <i key={i}>{e.params[1]}</i>,
    'u': (e, i) => <u key={i}>{e.params[1]}</u>,
    'h1': (e, i) => <h1 key={i}>{e.params[1]}</h1>,
    'h2': (e, i) => <h2 key={i}>{e.params[1]}</h2>,
    'h3': (e, i) => <h3 key={i}>{e.params[1]}</h3>,
    'h4': (e, i) => <h4 key={i}>{e.params[1]}</h4>,
    'h5': (e, i) => <h5 key={i}>{e.params[1]}</h5>,
    'br': (e, i) => <br key={i} />,
    'space': (e, i) => <div key={i} className="spacer" style={ { height: e.params[1]+'mm'} }/>,
    'logo': (e, i) => <div className="logo-container">
        <Dice color="white" className="first" />
        <Dice color="white" className="second" />
    </div>
}

const classMap = {
    'FL': 'float-left',
    'FR': 'float-right',
}

const modifierMap = {
    'C': (p) => ({ color: p })
}

function getOptions(string) {
    const result = {
        classes: [],
        other: {},
        params: [],
    }
    if (!string) { return result }
    const options = string.split(',');
    options.forEach((o) => {
        const c = o.charAt(0);
        console.log('xx' + o);
        if (c === '+') {
            const whole = o.substring(1);
            const exploded = whole.split('=');
            console.log(exploded);
            if (exploded.length === 1) {
                const classID = exploded[0];
                const className = classMap[classID];
                result.classes.push(className ? className : classID)
                console.log('***', result)
            } else {
                const modID = exploded[0];
                const modObject = modifierMap[modID];
                if (modObject !== undefined) {
                    const resObject = modObject(exploded[1]);
                    result.other = { ...result.other, ...resObject }
                }
            }
        }
    })
    console.log(options);
    result.params = options;
    return result
}

const bracketed = /\[([A-Za-z0-9_ \-]+)([A-Za-z0-9_\-=+*^;:,.! '"]+)*\]/g;

export function transformText(t) {
    const out = [];
    let lasti = 0;
    let match;
    while ((match = bracketed.exec(t)) != null) {
        console.log(match.join(','));
        const idiff = match.index - lasti;
        if (idiff > 0) {
            out.push(t.substring(lasti, lasti + idiff));
        }
        lasti = match.index + match[0].length;

        const keyWord = match[1];
        const f = funmap[keyWord];
        if (f) {
            console.log('qqq', match[2]);
            const options = getOptions(match[2]);
            console.log(options);
            out.push(f(options, lasti))
        } else {
            out.push(match[0]);
        }
    }
    console.log(t, t.length, lasti);
    if (t.length > lasti) {
        out.push(t.substring(lasti, t.length));
    }
    if (lasti === 0) {
        return t;
    }
    return out;
}
