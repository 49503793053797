import { store } from "@risingstack/react-easy-state";


export default store({
    selectedPage: {},
    pages: [
        {
            class: "cover nopagenumber",
            content: `[PageCover,FrankGlenn_org_modded.png,fade]
[space,6]
[h1,DoubleDice RPG]
[h2,Detective Noir handbook]
[logo]
`
        },
        {
            class: "",
            content: `
Message from the author:

### This is FREE at DoubleDiceRPG.com

Don't pay for this. If You did, You've been scammed.

&nbsp;

I'm working after hours on free materials useful for storytelling and RPG games online and offline. If You like what I do 
or have feedback, or (even better!) would like to contribute, let me know. 

There are links on the site to join a Discord server as well as a bit of software to create booklets like this one.

### Stay creative and fave fun :D 

<h3 class="bottom right padded5">by Tim Cabbage</h3>

<p class="bottom right padded3">TimCabbage.com</p>`
        },
        {
            class: 'story',
            content: `Frank Glenn was a good detective. He followed leads, questioned witnesses, poured over the evidence to make sure he didn't accuse the innocent. 
He was an honest man. And he used his gun twice only, both times in self defense, both times to injure, not to kill.

This changed today. 

He was walking down a side street, dressed in his working suit and a brown coat. With a trilby hat on his head, his attire practically screamed who he was. He wanted it that way though. He wanted to be seen, to be followed. To be caught. 

It was his last hope. 

See, there was someone abducting people who got too close to discovering their secrets. Seemed to work alone too which was quite good as Frank never liked fighting outnumbered or outgunned. 

Joe and Betty - the two reporters that disappeared, the orphan kid from the neighbourhood that was prowling the night with a notepad and a flashlight. Nobody really knew his name as he gave a different one every time.

Fifteen dogs found dead with bite and claw marks as if from a large predatory feline. Tiger or a lion, perhaps. Too clean though, to him looked prepared rather than just bitten and mauled. Police disagreed though, thought someone had a hungry tiger in a
basement somewhere, apparently. Morons. 

And then there was his wife. Patricia married him just three years ago, after he stopped drinking so much, started spending more time at home and not chasing yet another case. She was good for him. Disappeared almost a week ago now, around the
street he was walking on.

He found himself standing, suddenly blinded by a bright light coming from a window on the street corner. A soft thud alerted him that someone was trying to get him from behind. Knew enough about the attacker MO to sidestep back and to the right.
The guy was left handed. Sure enough, the baseball bat flew over his head. Had a nasty looking nail punched through the place where his head would've met the wood. Damn. Doesn't play around, does he.

Frank pushed further back, trying to put the assailant off balance, almost managed it but he was big. REALLY big. Six feet, five inches. Maybe six. Wide and muscular.

He threw his hat up to where their face would be and quickly jumped to the side, to regain some sight and get far away from that spiked club.

_- You were looking for me... -_ said a voice like boots on gravel.

_- Yes. -_ Frank was nervously scanning his surroundings to figure out where the big guy came out of. Looked a bit up, behind his assailants head. There was an empty hole where a window would normally be. And inside he saw his wife's shoe flop
onto the ledge upside down, as if kicked. Alive and kicking. Good. Relief. Most of the tension went`

        },
        {
            class: 'story',
            content: `<p class="noindent">out of him. Now there was just this problem towering over him, moving with weird, smooth motions.</p>

_- And why is that? Did I do something to your... dog?_

Frank moved back a bit to get further away from large gloves the giant was wearing. That, and the bat with a nail through it.

_- You kidnapped my wife. -_ Frank motioned behind his opponent. Perhaps he'll look back there, be distracted for just a moment. Didn't work though.
The giant didn't blink. stared at him, almost motionless, ready to... pounce? Franks instincts were giving him weird images of an overgrown cat hunting his prey. And the prey was him. He didn't
like that feeling not one bit. And were those vertical slits in the eyes?

_- Did I now...? Which one was it? The one with glasses that wore leather? Or the little one? - here he got thoughtful --- his eyes straying from Franks for a moment - No, You don't marry the little ones. Red
dress? blue? White with red dots, maybe? Which one was it? Tell me._

_- Sky blue. Brown tall shoes. -_ Glanced to the window again. His wife seemed to be alive and well. Unable to speak but able
to move her legs. First floor. And only the giant in the way. Need to keep him talking. To find a weakness or an opening.

_- Ah, yes. Was it six days ago? Five? Evening. Yes... -_ A weird smile split his face. Too wide. Too... greedy? Too many sharp teeth.

_- Is she alive? Is she well? -_ Frank moved to the side a little bit. Saw there's a locked and barred door right behind the cat-giant. He... jumped? Down here?

_- Maybe... Look. I like You. I'll give you your wife back. Whole, unharmed. I even gave her some food and water. Didn't feel like eating just yet. I'll give her back. -_ Pondered a moment _- For a price._

_- What do you want? -_ Frank asked. Too fast. Too eager. 

_Damn those feelings! I do love her though..._ he thought in a rush. 

_- A few little ones. They taste best. Never liked rats or dogs or cows or the old people you keep locked up. Young ones taste best. -_ Again, his eyes went off focus for just a little while.

Frank knew there might be something like this going on. By what the cat-giant was saying, he murdered a lot of people, ate them, all for a better tasting meal. Something clicked in his brain. This
wasn't a case where you brought them in. This was a case for justice to be done. Here and now. By his hand.

_- Let me see her and I'll try to set something up._

The giant was looking at him, unblinking, unsettling. Somehow Frank knew that he just lost the game. His opponent noticed the lie and was about to maul him to death.

A short step back, dodge to the right. A clawedhand... (paw?) missed his face by half an inch but left a gash in the coat. He pulled the gun out while 
still in the air and shot right in the open maw going for him. Saw the gun fire, saw blood spitting from the small hole in the back of the throat.`
        },
        {
            class: 'story',
            content: `Felt claws stab his side. Deep. Shot again. Grazed the skull by the eye that now reflected the light like a mirror. Moved the right arm lower, toward the
torso. Fired three more rounds.

And kept clicking the empty revolver to fire more. Three rounds in the heart. Saw the teeth coming over his shoulder, felt them piercing his skin,
spilling his blood. If the giant was still alive, the jaws would have tightened, Frank would have died and he'd have become just another meal. But dead
people don't bite.

Straining under the weight of the giant he pulled their claws out of his side with a muffled scream escaping him. Pushed the body off of himself, stood
up and holding his side with the left hand, started to stumble towards the hole.

_- He must have been jumping up there. -_ Said to himself while looking through a grimy window and seeing that the stairs have collapsed inside. 
_- Nothing to be done then. -_ and Frank started to climb up using the window and broken or missing bricks in the wall.

Inside he found his wife, gagged and bound with one missing shoe and a terrified look on her face. Frank hugged her tightly. For the first time he felt
his left foot getting wet inside the boot. Cut her restraints and the gag.

_- I love You, Patricia. It's dead now. Shot him. Five times. Got clawed though. -_ Pointed at his side needlessly.

They talked for a little bit, treated their immediate injuries and got out rather quickly. Later, police claimed that they found and killed the serial
killer and rescued one of their victims. Lots of applause. Lots of money. Lots of corruption. I guess that's how it works down here.

They both moved out of the city afterwards, decided to live out the rest of their days somewhere quiet and simple. Got their wish too, when Patricias
father died and left them a small farm deeper in the country. At his father-in-law's funeral, Frank thought he saw someone really tall, standing in the
distance, staring at the group by the grave. Not at him.

_- What if the cat-giant had a family? -_ he heard himself asking under his breath.

_- Hm? -_ Patricia said absentmindedly through her tears.

_- Nothing, honey. Just thinking... sorry. -_ and in the blink of his eyes, the giant was gone. Would he find Frank? Maybe. Not too much to go on though. An
old lead. Not much evidence. Police took the credit too. Maybe for the best. Didn't chief Boone get mauled to death one night though? Shiver ran through Frank.

He brushed his holster to check if it was properly heavy. It was. Well. If it comes to that, he'll be ready. Did it once, will do that again.

&nbsp;

For Patricia, _everything._`
        },
        {
            class: '',
            content: `### System introduction
            
DoubleDice RPG is a simple RPG system that aims to be faster and easier to work with than D&D but more structured and complex than FATE.

The basic character sheet contains six attributes: **Strength**, **Agility**, **Consitution**, **Intellect**, **Perception** and **Composure**. It also contains
six approaches: **Forceful**, **Quick**, **Flashy**, **Clever**, **Careful** and **Sneaky**.

Each of the attributes and each of the approaches is expressed by a dice: D4, D6, D8, D10 or D12. 

For example, in the character sheet below **Strength** is a **D8** (an 8 sided dice) 
and **Clever** is a **D6** (a 6 sided dice).

The higher the dice, the better the character is at using that aspect of themselves. A **D8 Strength** means that the character is stronger than a **D4 Strength**.

Characters are tested during the course of a story, and it is done by rolling two dice and adding what comes up. An example of a test in a combat situation could look like this:

>Frank needs to get past a thug as fast as possible. Chooses to *trick him that he's trying to punch him from the right but actually dive left and roll to get past the thug.*
>
>Frank would roll two dice: **Agility** and **Sneaky**. **Agility** because his manuever is relying on fast and precise movements, **Sneaky** because he's trying to trick his opponent.
>
>Because his enemies perception is a D4, Frank needs to beat a 4 to succeed. 
>
><p class="center big"><b>D4+D8 > 4</b></p>
>
>Thus our test is rather easy with an 80% success rate.

>Frank tries to figure out where an opponent went. Chooses to carefully investigate the location so as not to disturb the scene.
>
>Frank would roll two dice: **Perception** and **Careful**. **Perception** because he's looking, touching and smelling
, **Careful** because he's trying to not leave traces of him being there. He also receives a +2 to the roll because of his **Investigation** skill.
>
>His enemy left trying to lose his tail so we'll use his **Sneaky** approach which is a D8. Frank needs to beat an 8 so has to roll a 9 or more to succeed. 
>
><p class="center big"><b>D8 + D4 + 2 > 8</b></p>
>
>Thus our test is moderate with a 56% success rate.



            `
        },

        {
            class: '',
            content: `
<div class="character-sheet big">
    <div class="center bb">Frank Glenn</div>
    <div class="center bb"></div>
    <div class="center bb">Private detective</div>
    <div class="center bb"></div>    
    <div class=""></div>
    <div class="right">[D4][D6][D8][D10][D12]</div>
    <div class=""></div>
    <div class="right">[D4][D6][D8][D10][D12]</div>
    <div>Strength</div>
    <div class="right">[X][X][X][ ][ ]</div>
    <div>Forceful</div>
    <div class="right">[X][X][ ][ ][ ]</div>
    <div>Agility</div>
    <div class="right">[X][ ][ ][ ][ ]</div>
    <div>Quick</div>
    <div class="right">[X][X][ ][ ][ ]</div>
    <div>Consitution</div>
    <div class="right">[X][X][ ][ ][ ]</div>
    <div>Flashy</div>
    <div class="right">[X][ ][ ][ ][ ]</div>
    <div>Intellect</div>
    <div class="right">[X][X][ ][ ][ ]</div>
    <div>Clever</div>
    <div class="right">[X][X][ ][ ][ ]</div>
    <div>Perception</div>
    <div class="right">[X][X][X][ ][ ]</div>
    <div>Careful</div>
    <div class="right">[X][ ][ ][ ][ ]</div>
    <div>Composure</div>
    <div class="right">[X][X][X][ ][ ]</div>
    <div>Sneaky</div>
    <div class="right">[X][X][X][ ][ ]</div>
    <div class="bt bb">Effort</div>    
    <div class="bt bb right">[@][@][O][O][U][U][U]</div>    
    <div class="bt bb">Wounds</div>    
    <div class="bt bb right">[@][O][O][U][U][U][U]</div>        
    <div>Acrobatics</div>    
    <div class="right">[O][O][O]</div>    
    <div>Medicine</div>    
    <div class="right">[@][O][O]</div>    
    <div>Animals</div>    
    <div class="right">[O][O][O]</div>    
    <div>Melee Combat</div>    
    <div class="right">[@][O][O]</div>    
    <div>Athletics</div>    
    <div class="right">[O][O][O]</div>    
    <div>Nature</div>    
    <div class="right">[O][O][O]</div>    
    <div>Deception</div>    
    <div class="right">[@][O][O]</div>    
    <div>Perception</div>    
    <div class="right">[@][O][O]</div>    
    <div>Dexterity</div>    
    <div class="right">[O][O][O]</div>    
    <div>Performance</div>    
    <div class="right">[O][O][O]</div>    
    <div>Guns</div>    
    <div class="right">[O][O][O]</div>    
    <div>Persuasion</div>    
    <div class="right">[O][O][O]</div>    
    <div>History</div>    
    <div class="right">[@][O][O]</div>    
    <div>Religion</div>    
    <div class="right">[O][O][O]</div>    
    <div>Insight</div>    
    <div class="right">[@][@][O]</div>    
    <div>Stealth</div>    
    <div class="right">[O][O][O]</div>    
    <div>Intimidation</div>    
    <div class="right">[@][O][O]</div>    
    <div>Supernatural</div>    
    <div class="right">[@][O][O]</div>    
    <div>Investigation</div>    
    <div class="right">[@][@][O]</div>    
    <div>Survival</div>    
    <div class="right">[@][O][O]</div>    
</div>

&nbsp;

This is Franks character sheet. From the top we see his name and profession. below there's the section with attributes
and approaches. Next section contains **Effort** and **Wounds**.

**Effort** is a counter for how many times a character can "push" themselves to avoid one wound from an attack or to 
reroll one or both dice. Effort comes back every rest.

**Wounds** is a counter of how many times a character has been wounded. Until the last wound is marked, the wounds are superficial and may 
situationally inhibit a character (-1 to a particular test) but generally do not do so. An example may be blood dripping into the eye or a 
hurt leg not being fully steady. It's up to the GM discretion, but if there's question of fairness here, an optional rule is that
when you receive a wound, roll a D10 and if it's lower than your current wounds, characters next roll has a -1.

Notice also that there are filled dots [@] which denote a "used" resource, empty dots [O] which denote an available resource
and the faint boxes [U] which are unavailable to the character now but may in the future (outlined on gaining a higher maximum)

Below there's a list of skills. Each [@] dot is a +1 to a roll.
            `
        },
        {
            class: '',
            content: `### Tests

When any character is tested, they can **critically succeed** or **critically fail**. 
In each case both dice are at maximum and minimum values respectively.

#### Critical success
So when rolling a D4 + D8, D4 needs to roll a 4 and D8 needs to roll an 8 to critically succeed. Such a success
can be treated in two ways
* If a player is rolling to beat a particular difficulty and even a crit doesn't reach it, player may choose to
roll the smaller die again and add it to the result. (so it becomes D4 + D8 + (extra) D4, so 4 + 8 + whatever the next
D4 is)
* If a player decides to "hit harder", he can also upgrade the effect. Say a player is trying to stab someone at
D6 + D8, and rolls a 6 + 8 for a crit success. If 14 hits, he can decide to deal one more wound. Or, if it's to 
convince someone to give them 3000$ upfront for equipment, they may decide to drop 4000$ to be safe.

#### Critical failure
When both dice end up on a 1, the attempt fails. 

Optionally, if another roll of the lower dice ends up on a 1 as well, something bad happens. Break a leg!

### Choosing attributes and approaches
What a character uses to achieve a particular aim is strictly narrative driven. 

* Deceptive manuever in a fight would use **Strength** and **Sneaky**.

* Fighting in formation would likely be **Composure** and **Forceful**

* Shooting with a sniper rifle would use **Perception** or **Agility** and **Careful**. 

But it all depends on what the situation is.

> To choose the attribute, look at what's the main challenge that the character is overcoming. Lifting would be **Strength**. Lockpicking **Agility**.

> To choose the approach, you need to figure out what's the "pressing matter" in the current situation. In a hurry? **Quick**. Want to be seen or intimidate? **Flashy**.

            `
        },
        {
            class: '',
            content: `### Difficulty

Tests need a difficulty. 

> When tests are made against a character, one attribute or approach is used to determine the difficulty.

The defense needs to be **overcome** so if someone's **Constitution** is tested, then we take the "dice" value of it and 
use the number as the difficulty. A D8 in **Constitution** is a difficulty of 8.

When both characters act actively against each other, both roll and then they both can add skills to their tests.

#### Useful values

Any skill bonuses reduce the difficulty by the skill amount - a person with skill = 3 can pass a challenging test as a very easy test.

* **3: very easy** test. A person with low dice (D4 + D4) has 81% chance of succeeding here.
* **4: easy** test. (D4 + D4) => 62%, (D6 + D6) => 83%
* **5: normal** test. (D4 + D4) => 37%, (D6 + D6) => 72%
* **6: challenging** test. (D6 + D6) => 58%, (D8 + D8) => 76%
* **7: very challenging** test. (D8 + D8) => 67%, (D10 + D10) => 79%
* **8: hard** test. (D8 + D8) => 56%. (D10 + D10) => 72%.
* **9: very hard** test. (D10 + D10) => 64%. (D12 + D12) => 75%.
* **10: extremely hard** test. (D10 + D10) => 64%. (D12 + D12) => 68%.
* **11: super hard** test. (D10 + D10) => 45%. (D12 + D12) => 62%.
* **12: ??** test. (D10 + D10) => 36%. (D12 + D12) => 54%.
* **13: ???** test. (D12 + D12) => 45%.
* **14: ???** test. (D12 + D12) => 38%.
* **15: ???** test. (D12 + D12) => 31%.
* **16: ???** test. (D12 + D12) => 25%.
* **17: ???** test. (D12 + D12) => 19%.
* **18: ???** test. (D12 + D12) => 15%.
            `
        },
        {
            class: '',
            content: `### Combat

One form of testing is combat. In combat, two or more characters test their combat prowess against each other.

Each success of an attacker causes one wound to the defender. Defender can then use effort once to try and change the result.
A particularly deadly attack or an attack where someone's at a disadvantage, may cause two or more wounds. Wearing armor
reduces the attackers advantage and in some cases may give advantage to the defender. In that case it's really hard to harm
the armored person.

When there's no clear advantage or a disadvantage (ie both combatants are fighting roughly on the same terms), it's a test
that depends on the narrative. Any pair of attribute and approach dice can be used.

Use combat advantage sparingly as it can easily turn an encounter from acceptable to impossible.

One situation where it's always good to use is when fighing a much bigger enemy. Trying to shoot, punch or cut a 12ft giant would
not yield good results most of the time. Conversely, hitting a small target is harder than normal so a bonus of a +1 to +3 to 
dodge rolls may be good, but damage from such a hit would cause much more damage (2, 3 or even 4 wounds).



            `
        },
        {
            notInBooklet: true,
            class: 'nopagenumber nocharsheetborder',
            content: `
<div class="character-sheet big">
    <div class="center bb">&nbsp;</div>
    <div class="center bb"></div>
    <div class="center bb"></div>
    <div class="center bb"></div>    
    <div class=""></div>
    <div class="right">[D4][D6][D8][D10][D12]</div>
    <div class=""></div>
    <div class="right">[D4][D6][D8][D10][D12]</div>
    <div>Strength</div>
    <div class="right">[ ][ ][ ][ ][ ]</div>
    <div>Forceful</div>
    <div class="right">[ ][ ][ ][ ][ ]</div>
    <div>Agility</div>
    <div class="right">[ ][ ][ ][ ][ ]</div>
    <div>Quick</div>
    <div class="right">[ ][ ][ ][ ][ ]</div>
    <div>Consitution</div>
    <div class="right">[ ][ ][ ][ ][ ]</div>
    <div>Flashy</div>
    <div class="right">[ ][ ][ ][ ][ ]</div>
    <div>Intellect</div>
    <div class="right">[ ][ ][ ][ ][ ]</div>
    <div>Clever</div>
    <div class="right">[ ][ ][ ][ ][ ]</div>
    <div>Perception</div>
    <div class="right">[ ][ ][ ][ ][ ]</div>
    <div>Careful</div>
    <div class="right">[ ][ ][ ][ ][ ]</div>
    <div>Composure</div>
    <div class="right">[ ][ ][ ][ ][ ]</div>
    <div>Sneaky</div>
    <div class="right">[ ][ ][ ][ ][ ]</div>
    <div class="bt bb">Effort</div>    
    <div class="bt bb right">[O][U][U][U][U][U][U]</div>    
    <div class="bt bb">Wounds</div>    
    <div class="bt bb right">[O][U][U][U][U][U][U]</div>        
    <div>Acrobatics</div>    
    <div class="right">[O][O][O]</div>    
    <div>Medicine</div>    
    <div class="right">[O][O][O]</div>    
    <div>Animals</div>    
    <div class="right">[O][O][O]</div>    
    <div>Melee Combat</div>    
    <div class="right">[O][O][O]</div>    
    <div>Athletics</div>    
    <div class="right">[O][O][O]</div>    
    <div>Nature</div>    
    <div class="right">[O][O][O]</div>    
    <div>Deception</div>    
    <div class="right">[O][O][O]</div>    
    <div>Perception</div>    
    <div class="right">[O][O][O]</div>    
    <div>Dexterity</div>    
    <div class="right">[O][O][O]</div>    
    <div>Performance</div>    
    <div class="right">[O][O][O]</div>    
    <div>Guns</div>    
    <div class="right">[O][O][O]</div>    
    <div>Persuasion</div>    
    <div class="right">[O][O][O]</div>    
    <div>History</div>    
    <div class="right">[O][O][O]</div>    
    <div>Religion</div>    
    <div class="right">[O][O][O]</div>    
    <div>Insight</div>    
    <div class="right">[O][O][O]</div>    
    <div>Stealth</div>    
    <div class="right">[O][O][O]</div>    
    <div>Intimidation</div>    
    <div class="right">[O][O][O]</div>    
    <div>Supernatural</div>    
    <div class="right">[O][O][O]</div>    
    <div class="bb">Investigation</div>    
    <div class="right bb">[O][O][O]</div>    
    <div class="bb">Survival</div>    
    <div class="right bb">[O][O][O]</div>    
</div>



            `
        }        
    ]
})