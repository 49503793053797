function dice(props) {
    return `
<div class="${"Crystal " + props.color + ' ' + (props.class ? props.class : '')}" style="${props.style}">
    <div className="text">${props.content}</div>
    <svg viewBox="0 0 256 256" width="10mm">
        <g>
            <path
                d=" M 217.279 151.721 L 134.721 234.279 C 131.011 237.989 124.989 237.989 121.279 234.279 L 38.721 151.721 C 35.011 148.011 32 140.741 32 135.496 L 32 110.504 C 32 105.259 35.172 98.158 39.079 94.658 L 120.921 21.342 C 124.828 17.842 131.172 17.842 135.079 21.342 L 216.921 94.658 C 220.828 98.158 224 105.259 224 110.504 L 224 135.496 C 224 140.741 220.989 148.011 217.279 151.721 Z "
                fill="rgb(0,0,0)" vectorEffect="non-scaling-stroke" strokeWidth="0.32mm"
                stroke="rgb(0,0,0)" strokeLinejoin="miter" strokeLinecap="square"
                strokeMiterlimit="3" />
            <path
                d=" M 122.294 168.489 L 68.706 135.511 C 65.557 133.573 64.458 129.376 66.254 126.143 L 95.5 73.5 L 124.746 20.857 C 126.542 17.624 129.458 17.624 131.254 20.857 L 189.746 126.143 C 191.542 129.376 190.443 133.573 187.294 135.511 L 133.706 168.489 C 130.557 170.427 125.443 170.427 122.294 168.489 Z "
                fill="rgb(255,255,255)" vectorEffect="non-scaling-stroke" strokeWidth="0.16mm"
                stroke="rgb(0,0,0)" strokeLinejoin="miter" strokeLinecap="square"
                strokeMiterlimit="3" />
            <path
                d=" M 38.179 142.409 L 56.821 134.591 C 60.231 133.161 64.458 129.376 66.254 126.143 L 124.746 20.857 C 126.542 17.624 125.764 17.003 123.01 19.471 L 36.99 96.529 C 34.236 98.997 32 104.002 32 107.7 L 32 138.3 C 32 141.998 34.769 143.839 38.179 142.409 Z "
                fill="rgb(255,255,255)" vectorEffect="non-scaling-stroke" strokeWidth="0.16mm"
                stroke="rgb(0,0,0)" strokeLinejoin="miter" strokeLinecap="square"
                strokeMiterlimit="3" />
            <path
                d=" M 217.821 142.409 L 199.179 134.591 C 195.769 133.161 191.542 129.376 189.746 126.143 L 131.254 20.857 C 129.458 17.624 130.236 17.003 132.99 19.471 L 219.01 96.529 C 221.764 98.997 224 104.002 224 107.7 L 224 138.3 C 224 141.998 221.231 143.839 217.821 142.409 Z "
                fill="rgb(255,255,255)" vectorEffect="non-scaling-stroke" strokeWidth="0.16mm"
                stroke="rgb(0,0,0)" strokeLinejoin="miter" strokeLinecap="square"
                strokeMiterlimit="3" />
            <path
                d=" M 38.179 142.409 L 56.821 134.591 C 60.231 133.161 65.557 133.573 68.706 135.511 L 122.294 168.489 C 125.443 170.427 128 175.002 128 178.7 L 128 234.3 C 128 237.998 125.877 238.877 123.262 236.262 L 36.738 149.738 C 34.123 147.123 34.769 143.839 38.179 142.409 Z "
                fill="rgb(255,255,255)" vectorEffect="non-scaling-stroke" strokeWidth="0.16mm"
                stroke="rgb(0,0,0)" strokeLinejoin="miter" strokeLinecap="square"
                strokeMiterlimit="3" />
            <path
                d=" M 217.821 142.409 L 199.179 134.591 C 195.769 133.161 190.443 133.573 187.294 135.511 L 133.706 168.489 C 130.557 170.427 128 175.002 128 178.7 L 128 234.3 C 128 237.998 130.123 238.877 132.738 236.262 L 219.262 149.738 C 221.877 147.123 221.231 143.839 217.821 142.409 Z "
                fill="rgb(255,255,255)" vectorEffect="non-scaling-stroke" strokeWidth="0.16mm"
                stroke="rgb(0,0,0)" strokeLinejoin="miter" strokeLinecap="square"
                strokeMiterlimit="3" />
        </g>
    </svg>
</div>`;

}


const funmap = {
    ' ': (e, i) => `<i class="far fa-fw fa-square"></i>`,
    '_': (e, i) => `<i class="far fa-fw fa-square gray"></i>`,
    'X': (e, i) => `<i class="fas fa-fw fa-square"></i>`,
    'O': (e, i) => `<i class="far fa-fw fa-circle"></i>`,
    '@': (e, i) => `<i class="fas fa-fw fa-circle"></i>`,
    'U': (e, i) => `<i class="far fa-fw fa-circle gray"></i>`,
    'D4': (e, i) => `<i class="far fa-fw fa-dice-d4"></i>`,
    'D6': (e, i) => `<i class="far fa-fw fa-dice-d6"></i>`,
    'D8': (e, i) => `<i class="far fa-fw fa-dice-d8"></i>`,
    'D10': (e, i) => `<i class="far fa-fw fa-dice-d10"></i>`,
    'D12': (e, i) => `<i class="far fa-fw fa-dice-d12"></i>`,
    'Dice': (e, i) => dice(e),
    'PageCover': (e, i) => `<div class="page-image" style="background-image: url(${e.params[1]})"></div>`,
    'b': (e, i) => `<b>${e.params[1]}</b>`,
    'i': (e, i) => `<i>${e.params[1]}</i>`,
    'u': (e, i) => `<u>${e.params[1]}</u>`,
    'h1': (e, i) => `<h1>${e.params[1]}</h1>`,
    'h2': (e, i) => `<h2>${e.params[1]}</h2>`,
    'h3': (e, i) => `<h3>${e.params[1]}</h3>`,
    'h4': (e, i) => `<h4>${e.params[1]}</h4>`,
    'h5': (e, i) => `<h5>${e.params[1]}</h5>`,
    'br': (e, i) => `<br />`,
    'space': (e, i) => `<div class="spacer" style="height: ${e.params[1]}mm"></div>`,
    'logo': (e, i) => `<div class="logo-container">
        ${dice({color: 'white', class: 'first', content: ''})}
        ${dice({color: 'white', class: 'second', content: ''})}
    </div>`
}

const classMap = {
    'FL': 'float-left',
    'FR': 'float-right',
}

const modifierMap = {
    'C': (p) => ({ color: p })
}

function getOptions(string) {
    const result = {
        classes: [],
        other: {},
        params: [],
    }
    if (!string) { return result }
    const options = string.split(',');
    options.forEach((o) => {
        const c = o.charAt(0);
        if (c === '+') {
            const whole = o.substring(1);
            const exploded = whole.split('=');
            if (exploded.length === 1) {
                const classID = exploded[0];
                const c = classMap[classID];
                result.classes.push(c ? c : classID)
            } else {
                const modID = exploded[0];
                const modObject = modifierMap[modID];
                if (modObject !== undefined) {
                    const resObject = modObject(exploded[1]);
                    result.other = { ...result.other, ...resObject }
                }
            }
        }
    })
    result.params = options;
    return result
}

const bracketed = /\[([A-Za-z0-9_ @\-]+)([A-Za-z0-9_\-=+*^;:,.! '"]+)*\]/g;

export function preprocessText(t) {
    const out = [];
    let lasti = 0;
    let match;
    while ((match = bracketed.exec(t)) != null) {
        const idiff = match.index - lasti;
        if (idiff > 0) {
            out.push(t.substring(lasti, lasti + idiff));
        }
        lasti = match.index + match[0].length;

        const keyWord = match[1];
        const f = funmap[keyWord];
        if (f) {
            const options = getOptions(match[2]);
            out.push(f(options, lasti))
        } else {
            out.push(match[0]);
        }
    }
    if (t.length > lasti) {
        out.push(t.substring(lasti, t.length));
    }
    if (lasti === 0) {
        return t;
    }
    return out.join('');
}
